import React from 'react';
import config from '../../config';
import ListingImageGallery from './ListingImageGallery/ListingImageGallery';

import css from './ListingPage.module.css';

const SectionGallery = props => {
  const { listing,handleFavouriteItems,index,handleSocialShare, setImgIndex } = props;  
  const images = listing.images;
  const {tagsImageArray  } = listing.attributes.publicData || {} ;  
  // const { variantPrefix } = config.listing;
  const imageVariants = [
    'landscape-crop',
    'landscape-crop2x',
    'landscape-crop4x',
    'landscape-crop6x',
  ];
  // const thumbnailVariants = [variantPrefix, `${variantPrefix}-2x`, `${variantPrefix}-4x`];


  

  return (
    <ListingImageGallery
      indexValue={index}
      setImgIndex={setImgIndex}
      rootClassName={css.productGallery}

      listing={listing}
      images={images.filter((e)=>!tagsImageArray?.includes(e.id.uuid))}
      imageVariants={imageVariants}

    />
  );
};

export default SectionGallery;
