import React, { useState } from 'react';
import { FormattedMessage } from '../../util/reactIntl';
import { IconShare, InlineTextButton, ListingIconCard, SocialShare } from '../../components';
import {

  getPageURL,
} from '../../util/data';
import css from './ListingPage.module.css';

const SectionHeading = props => {
  const [isOpenSocialModel, setIsOpenSocialModel] = useState(false);
  const PageUrl = getPageURL();
  const {
    richTitle,
    showContactUser,
    onContactUser,
    localHireTrade,
    mentoringFellowPros,
    publicData,
    onManageDisableScrolling
  } = props;
  return (
    <div className={css.sectionHeadingBox}>
      <div className={css.headingBox}>
        <h1 className={css.title}>{richTitle}</h1>
 
        <span className={css.shareButton}>
          <InlineTextButton
            // rootClassName={css.contactLink}
            onClick={() => setIsOpenSocialModel(true)}
            enforcePagePreloadFor="SignupPage"
          >
            <IconShare type="shareOrange" />
            <FormattedMessage id="ListingPage.contactUser" />
          </InlineTextButton>
        </span>
        <SocialShare
          isOpen={isOpenSocialModel}
          onClose={() => setIsOpenSocialModel(false)}
          onManageDisableScrolling={onManageDisableScrolling}
          url={PageUrl}
          publicData={publicData}
        />
        {/* {localHireTrade
          ? <div className={css.avlLocalHire}>
            <span>
              <ListingIconCard type="location" />
            </span>
            <FormattedMessage id="SectionHeading.availableForLocalHire" />
            <div className={css.author}>
              {showContactUser ? (
                <span className={css.contactWrapper}>
                  <InlineTextButton
                    rootClassName={css.contactLink}
                    onClick={onContactUser}
                    enforcePagePreloadFor="SignupPage"
                  >
                    <FormattedMessage id="ListingPage.sendEnquiry" />
                  </InlineTextButton>
                </span>
              ) : null}
            </div>
         
          </div>
          : null} */}

        {/* {mentoringFellowPros && <div>Mentoring fellow pros</div>} */}
      </div>

    </div>
  );
};

export default SectionHeading;
