import React,{ useState }  from 'react';
import { FormattedMessage } from '../../util/reactIntl';
import { ResponsiveImage, Modal, ImageCarousel } from '../../components';
import ActionBarMaybe from './ActionBarMaybe';

import css from './ListingPage.module.css';
import SectionGallery from './SectionGallery';
import classNames from 'classnames';

const SectionImages = props => {
  const {
    title,
    listing,
    isOwnListing,
    editParams,
    handleViewPhotosClick,
    imageCarouselOpen,
    onImageCarouselClose,
    onManageDisableScrolling,
  } = props;
  const [imgIndex, setImgIndex] = useState("");
  const hasImages = listing.images && listing.images.length > 0;
  let firstImage = hasImages ? listing.images[0] : null;
  const { defaultImg
  } = listing && listing.attributes && listing.attributes.publicData && listing.attributes.publicData && listing.attributes.publicData.trades[0] || {};



  // Action bar is wrapped with a div that prevents the click events
  // to the parent that would otherwise open the image carousel
  const actionBar = listing.id ? (
    <div onClick={e => e.stopPropagation()}>
      <ActionBarMaybe isOwnListing={isOwnListing} listing={listing} editParams={editParams} />
    </div>
  ) : null;

  const viewPhotosButton = hasImages ? (
    <button className={css.viewPhotos} onClick={handleViewPhotosClick}>
      <FormattedMessage
        id="ListingPage.viewImagesButton"
        values={{ count: listing.images.length }}
      />
    </button>
  ) : null;

  return (
    <div className={classNames(css.sectionImages,hasImages && listing.images.length > 6 && css.sectionImagePadding) }>
      <div className={css.threeToTwoWrapper}>
        <div  className={css.aspectWrapper}
          onClick={handleViewPhotosClick}>
          {actionBar}
          {firstImage ? <>

<SectionGallery rootClassName={css.rootForImage} listing={listing} setImgIndex={setImgIndex} />
{viewPhotosButton}</> : <img src={defaultImg} className={css.defaultCategoryImage} />
}
        </div>
      </div>
      <Modal
        id="ListingPage.imageCarousel"
        scrollLayerClassName={css.carouselModalScrollLayer}
        containerClassName={css.carouselModalContainer}
        lightCloseButton
        isOpen={imageCarouselOpen}
        onClose={onImageCarouselClose}
        usePortal
        onManageDisableScrolling={onManageDisableScrolling}
      >
        <ImageCarousel
          images={listing.images}
          imgIndex={imgIndex}
          imageVariants={['scaled-small', 'scaled-medium', 'scaled-large', 'scaled-xlarge']}
        />
      </Modal>
    </div>
  );
};

export default SectionImages;