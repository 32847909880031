import React from 'react';
import { ListingIconCard } from '../../components';

import css from './ListingPage.module.css';

const SectionDifferentTrades = props => {
    const { trades = [], selectedTrade } = props;

    return (trades && Array.isArray(trades) && trades.length
        ? <div className={css.accordanceBoxWraper}>
            {trades.map((trade, index) => (
                <details key={trade + '-' + index} className={css.accordanceBox} open={selectedTrade ? trade.key == selectedTrade : index == 0}>
                    <summary className={css.accordionTitle}>
                        <div className={css.leftBox}>
                            <h2 className={css.descriptionTitle}>
                                <>{trade.label}</>
                            </h2>
                        </div>
                    </summary>
                    <div className={css.faqBody}>

                        <div className={css.accordionDescription}>
                            <ul className={css.mainList}>
                                {trade && trade.requirements &&
                                    Array.isArray(trade.requirements)
                                    && trade.requirements.length
                                    ? trade.requirements.map((requirement, i) =>
                                        <li className={css.tradeList} key={requirement + '-' + i}>
                                            <span>
                                                <ListingIconCard type="tradecheck" />
                                            </span>
                                            <span className={css.requirementHeading}>{requirement}</span>
                                        </li>
                                    )
                                    : null}
                            </ul>
                        </div>
                        <div className={css.accordionDescription}>
                            <ul className={css.mainList}>
                                {trade && trade.not_officaly_certification
                                    &&
                                    Array.isArray(trade.not_officaly_certification
                                    )
                                    && trade.not_officaly_certification
                                        .length
                                    ? <> <div className={css.specialitiesTitle} >Specialties</div>{trade.not_officaly_certification
                                        .map((requirement, i) =>
                                            <li className={css.tradeList} key={requirement + '-' + i}>

                                                <span className={css.requirementHeading}>{requirement}</span>
                                            </li>
                                        )}</>
                                    : null}
                            </ul>
                        </div>
                    </div>
                </details>
            ))}
        </div>
        : null)
}

export default SectionDifferentTrades;
